import React, { useState, useEffect } from 'react';

import { useAuth0 } from "@auth0/auth0-react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

import { ReactComponent as GemminisLogoWhite } from '../../assets/logos/gemminis-white.svg';

const pages = [
  ['Inicio', '/'],
  ['Inventarios digitales', '/inventarios/'],
  ['Catálogo', '/catalogo/'],
  ['Planes', '/planes/'],
  ['Demo', '/demo/'],
  ['Contacto', '/contacto/'],
  ['Empecemos', '/empecemos/']
];
const settings = ['Cerrar sesión'];

export default function NavbarGemminis() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const [hasScrolled, setHasScrolled] = useState(false);

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [getAccessTokenSilently]);

  const handleScroll = () => {
    // Check if the user has scrolled down (e.g., beyond 50 pixels)
    if (window.pageYOffset > 50) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });

  return (
    <AppBar position="sticky" elevation={hasScrolled ? 4 : 0}>
      <Container maxWidth={false}>
        <Toolbar disableGutters variant='dense'>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Link href='/'>
              <GemminisLogoWhite id="gemminis-logo-white" width="120px" style={{ paddingTop: '5px' }} />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Tooltip title="Menú" arrow enterDelay={500}>
              <IconButton
                size="small"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="secondary"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <MenuRoundedIcon sx={{ fontSize: 28 }} />
              </IconButton>
            </Tooltip>
            <Drawer
              variant="temporary"
              anchor="left"
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              PaperProps={{
                sx: {
                  backgroundColor: 'primary.main',
                  color: 'primary.contrastText',
                  width: 1,
                }
              }}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <Tooltip title="Cerrar" arrow enterDelay={500}>
                <IconButton
                  size="medium"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleCloseNavMenu}
                  color="inherit"
                  sx={{
                    justifyContent: 'left',
                    width: '46px',
                    ml: '14px',
                    mt: '6px',
                    mb: '2px',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <CloseRoundedIcon sx={{ fontSize: 28 }} />
                </IconButton>
              </Tooltip>
              {pages.map((page) => (
                <Link key={page[0]} href={page[1]} sx={{ textDecoration: 'none', color: 'inherit', marginLeft: '12px' }}>
                  <MenuItem
                    key={page[0]}
                    onClick={handleCloseNavMenu}
                    sx={{
                      position: 'relative',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                      '&:hover .arrow-icon': {
                        display: 'block'
                      },
                    }}
                  >
                    <Typography variant={page[0] === 'Empecemos' ? 'h3' : 'h4'}>{page[0]}</Typography>
                    <ArrowForwardIosRoundedIcon
                      className="arrow-icon"
                      sx={{ display: 'none', position: 'absolute', right: '20px' }}
                    />
                  </MenuItem>
                </Link>
              ))}
            </Drawer>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-start' }}>
            <Link href='/'>
              <GemminisLogoWhite id="gemminis-logo-white" width="120px" style={{ paddingTop: '5px' }} />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
            {pages.map((page) => (
              <Button
                key={page[0]}
                href={page[1]}
                color='secondary'
                variant={page[0] === 'Empecemos' ? 'outlined' : 'text'}
                sx={{
                  mx: '6px',
                  minWidth: '10px',
                  textDecoration: 'none',
                  display: 'block',
                  textTransform: 'none',
                  fontWeight: 'regular',
                  fontSize: '14px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: 'secondary.contrastText',
                    borderColor: 'secondary.contrastText'
                  },
                }}
              >
                {page[0]}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {isAuthenticated ?
              <Box>
                <Tooltip title="Perfil" arrow enterDelay={500}>
                  <IconButton size="small" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user.name} src={user.picture} sx={{ width: 36, height: 36 }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={() => {
                      logoutWithRedirect();
                      handleCloseUserMenu();
                    }}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              :
              <Tooltip title="Iniciar sesión" arrow enterDelay={500}>
                <IconButton
                  size="small"
                  aria-label="Iniciar sesión"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => loginWithRedirect()}
                  color="secondary"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <PersonRoundedIcon sx={{ fontSize: 28 }} />
                </IconButton>
              </Tooltip>
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";

import './styles/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-z47jo263y2ridxjj.eu.auth0.com"
      //clientId="l0xPvv5lH0Qs67AiGpvYgQWyDLe3hVYX" //dev
      clientId="fNpkXPP4lPkm5D8xytm0QIvAzVq3jjlg" //prod
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://gemminis/api",
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

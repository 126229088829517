import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import { useTheme } from '@mui/material/styles';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import GradientBox from '../customs/GradientBox';
import GradientText from '../customs/GradientText';

export default function Plans() {

  const theme = useTheme();

  return (
    <Box id="plans">
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
          <Typography variant="h4" color="grey.800" gutterBottom fontWeight='medium' sx={{ mt: theme.spacing(2), mb: theme.spacing(4), textAlign: 'center' }}>
            Un plan sencillo y accesible para todos.
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} sx={{ mx: 'auto', mb: '20px', minWidth: '260px' }}>
              <Card sx={{ p: theme.spacing(3), textAlign: 'center', minWidth: '200px', borderRadius: theme.shape.borderRadius }}>
                <Typography variant="body1" gutterBottom>
                  Desde
                </Typography>
                <Typography variant="h1" color="primary" gutterBottom sx={{ my: '10px' }}>
                  50€
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  Por km interurbano analizado
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mx: 'auto', mb: '20px', minWidth: '260px' }}>
              <Card sx={{ p: theme.spacing(3), textAlign: 'center', minWidth: '200px', borderRadius: theme.shape.borderRadius }}>
                <Typography variant="body1" gutterBottom>
                  Desde
                </Typography>
                <Typography variant="h1" color="primary" gutterBottom sx={{ my: '10px' }}>
                  150€
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  Por km urbano analizado
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </GradientBox>
      <GradientBox>
        <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6), pt: theme.spacing(10) }}>
          <GradientText variant="h3" gutterBottom fontWeight='medium' sx={{ pb: '20px' }}>
            Defina su Inventario Digital y reciba un presupuesto personalizado.
          </GradientText>
          <Button
            color="primary"
            href='/empecemos/'
            variant="contained"
            size="large"
            aria-label="Start now"
            sx={{ fontSize: 'large', my: theme.spacing(4) }}
            endIcon={<ArrowForwardRoundedIcon />}
          >
            Empecemos
          </Button>
        </Box>
      </GradientBox>
    </Box>
  );
}
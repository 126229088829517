import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { useTheme } from '@mui/material/styles';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';

import { ReactComponent as GemminisLogoOrange } from '../../assets/logos/gemminis-orange.svg';

export default function FooterGemminis() {

  const theme = useTheme();

  return (
    <Box id="footer" style={{ backgroundColor: theme.palette.neutral.light }}>
      <Box sx={{ maxWidth: '1200px', m: '0 auto', p: theme.spacing(6) }}>
        <Grid container spacing={3} >
          <Grid item xs={12} md={6} lg={3} sx={{ mb: '20px' }}>
            <Link href='/'>
              <GemminisLogoOrange id="gemminis-logo-orange" width="200px" />
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ mb: '20px' }}>
            <Typography variant="body1" color="textPrimary" gutterBottom sx={{ fontSize: '18px', mb: '8px' }}>
              Sobre nosotros
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom sx={{ fontSize: '15px', pr: '10px', pt: '4px' }}>
              En Gemminis hemos desarrollado una solución que permite la obtención de Inventarios digitales de activos urbanos de forma automática.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ mb: '20px' }}>
            <Typography variant="body1" color="textPrimary" gutterBottom sx={{ fontSize: '18px', mb: '8px' }}>
              Contacto
            </Typography>
            <Box sx={{ mb: '4px'}}>
              <Button
                color="footerButton"
                href='mailto:info@gemminis.io'
                variant="text"
                size="small"
                startIcon={<EmailRoundedIcon />}
              >
                info@gemminis.io
              </Button>
            </Box>
            <Box sx={{ mb: '4px'}}>
              <Button
                color="footerButton"
                href='tel:+34971761150'
                variant="text"
                size="small"
                startIcon={<PhoneRoundedIcon />}
              >
                + 34 971 761 150
              </Button>
            </Box>
            <Box sx={{ mb: '4px'}}>
              <Button
                color="footerButton"
                href='https://www.linkedin.com/company/gemminis/'
                variant="text"
                size="small"
                startIcon={<ShareRoundedIcon />}
              >
                Síguenos en LinkedIn
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ mb: '20px' }}>
            <Typography variant="body1" color="textPrimary" gutterBottom sx={{ fontSize: '18px', mb: '8px' }}>
              Enlaces
            </Typography>
            <Box sx={{ mb: '4px'}}>
              <Button
                color="footerButton"
                href='/inventarios/'
                variant="text"
                size="small"
                aria-label="Demo"
                startIcon={<ArrowForwardRoundedIcon />}
              >
                Inventarios Digitales
              </Button>
            </Box>
            <Box sx={{ mb: '4px'}}>
              <Button
                color="footerButton"
                href='/catalogo/'
                variant="text"
                size="small"
                aria-label="Demo"
                startIcon={<ArrowForwardRoundedIcon />}
              >
                Catálogo
              </Button>
            </Box>
            <Box sx={{ mb: '4px'}}>
              <Button
                color="footerButton"
                href='/planes/'
                variant="text"
                size="small"
                aria-label="Demo"
                startIcon={<ArrowForwardRoundedIcon />}
              >
                Planes
              </Button>
            </Box>
            <Box sx={{ mb: '4px'}}>
              <Button
                color="footerButton"
                href='/demo/'
                variant="text"
                size="small"
                aria-label="Demo"
                startIcon={<ArrowForwardRoundedIcon />}
              >
                Demo
              </Button>
            </Box>
            <Box sx={{ mb: '4px'}}>
              <Button
                color="footerButton"
                href='/contacto/'
                variant="text"
                size="small"
                aria-label="Demo"
                startIcon={<ArrowForwardRoundedIcon />}
              >
                Contacto
              </Button>
            </Box>
            <Box sx={{ mb: '4px'}}>
              <Button
                color="footerButton"
                href='/empecemos/'
                variant="text"
                size="small"
                aria-label="Demo"
                startIcon={<ArrowForwardRoundedIcon />}
              >
                Empecemos
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ mb: '12px' }} />
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="body1" color="textSecondary" fontSize="14px" textAlign='center' sx={{ mt: '2px' }}>
              Copyright © 2023 Gemminis
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              color="primary"
              href='/privacidad/'
              variant="text"
              aria-label="Privacy"
              endIcon={<ArrowForwardRoundedIcon />}
              sx={{ textTransform: 'none', p: 0 }}
            >
              Aviso legal y política de privacidad
            </Button>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              color="primary"
              href='/cookies/'
              variant="text"
              aria-label="Cookies"
              endIcon={<ArrowForwardRoundedIcon />}
              sx={{ textTransform: 'none', p: 0 }}
            >
              Política de cookies
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}